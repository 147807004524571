import React from "react";
import {
  Box,
  ListItem,
  Heading,
  Text,
  UnorderedList,
  Center,
  Image,
  Flex,
} from "@chakra-ui/react";
import "../style/Depistage.css";
import Header from "../components/Nav";
import Footer from "../components/Footer";
import banniere from "../images/banniere-automate.png";

function Automate() {
  return (
    <>
      <Header />
      <Box className="content" backgroundColor={"#02011E"}>
        <Box className="depistageBackground" width={"100%"} height={"auto"}>
          <Image src={banniere} width={"100%"} height={"auto"} alt="" />
        </Box>

        <Box className="solutionContent" id="home">
          <Box className="title">
            <hr />
            <Heading as={"h2"} size={"lg"} mb={"15px"}>
              Logiciel automate de lecture{" "}
            </Heading>
          </Box>

          <Text>
            LoopDeeLab est un logiciel de pilotage en partenariat avec la
            société Loop Dee Science, permettant le maniement d'une flotte
            d'automate de lecture et d'avoir un suivi des analyses biologiques
            de manière numérique. Les automates réalisent des analyses rapides
            de différents pathogènes tels que le COVID-19.
          </Text>
          <Flex justifyContent={"space-between"} mt={"100px"}>
            <Box className="fonctionalite">
              <Heading as={"h3"} size={"md"} mb={"15px"}>
                Les fonctionnalités :
              </Heading>
              <UnorderedList>
                <ListItem>Connexion bluetooth ou port série</ListItem>
                <ListItem>
                  Connexion jusque’à dix automates de lecture par application
                </ListItem>
                <ListItem>Gestion de suivi des lots</ListItem>
                <ListItem>Historique des résultats</ListItem>
              </UnorderedList>
              <UnorderedList mt={"50px"}>
                <ListItem>Gestion des clients</ListItem>
                <ListItem>Gestion des communications</ListItem>
                <ListItem>Gestion des devis / Commandes</ListItem>
                <ListItem>
                  Gestion des licences du logiciel de pilotage des automates
                </ListItem>
                <ListItem>Plateforme dédiées aux clients </ListItem>
              </UnorderedList>
            </Box>
          </Flex>
        </Box>
      </Box>
      <>
        <Footer />
      </>
    </>
  );
}
export default Automate;
